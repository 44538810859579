import React from 'react';
import {Box, Container, Grid, Paper, Typography, useTheme} from "@mui/material";
import {
    LocationOn as LocationOnIcon,
    Mail as MailIcon,
    Phone as PhoneIcon, Public as GlobeIcon,
    VoiceChat as VoicemailIcon
} from "@mui/icons-material";

function Index() {
    const theme = useTheme();

    const sectionStyle = {
        width: '100%',
        padding: theme.spacing(6, 0),
    };

    const darkSectionStyle = {
        ...sectionStyle,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    };


    return (
        <Box component="section" sx={darkSectionStyle}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom>Contact Us</Typography>
                        <Box sx={{mb: 2}}>
                            {[
                                {icon: <LocationOnIcon/>, text: '123 Main St, Anytown USA'},
                                {icon: <PhoneIcon/>, text: '(123) 456-7890'},
                                {icon: <MailIcon/>, text: 'info@example.com'},
                                {icon: <VoicemailIcon/>, text: '(123) 456-7891'},
                                {icon: <GlobeIcon/>, text: 'www.example.com'},
                            ].map((item, index) => (
                                <Box key={index} sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 2}}>
                                    {React.cloneElement(item.icon, {color: 'primary'})}
                                    <Typography>{item.text}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom>Our Location</Typography>
                        <Paper sx={{overflow: 'hidden', borderRadius: theme.shape.borderRadius}}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27229.758243365635!2d74.30821794999999!3d31.449380549999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919014ca50af181%3A0x6630f48affe03d5d!2sTownship%20Lahore%2C%20Punjab!5e0!3m2!1sen!2s!4v1723887659709!5m2!1sen!2s"
                                width="100%"
                                height="450"
                                style={{border: 0}}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Lahore Township"
                            ></iframe>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Index;