import React from 'react';
import {
    Box,
    Container,
    Grid,
    Typography,
    Paper
} from '@mui/material';
import { styled } from '@mui/system';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';

const FeatureItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
        boxShadow: theme.shadows[4],
    },
}));

const FeatureIcon = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
}));

const items = [
    { icon: <LaptopMacIcon />, text: "High-Performance Laptops" },
    { icon: <SecurityIcon />, text: "Advanced Security Features" },
    { icon: <SpeedIcon />, text: "Lightning-Fast Processing" },
];

export default function Policies() {
    return (
            <Box sx={{ bgcolor: 'background.default', py: { xs: 6, md: 8, lg: 10 } }}>
                <Container maxWidth="lg">
                    <Grid container spacing={4}>
                        {items.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <FeatureItem elevation={1}>
                                    <FeatureIcon>
                                        {item.icon}
                                    </FeatureIcon>
                                    <Typography variant="h6" component="h3" gutterBottom>
                                        {item.text}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </Typography>
                                </FeatureItem>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
    );
}