import React from 'react';
import {Box} from '@mui/material';

import Achievements from "./Achievements";
import Testomonials from "./Testomonials";
import Services from "./Services";
import Form from "./Form";

export default function ContactPage() {

    return (
        <Box sx={{width: '100%'}}>
            <Form/>

            <Services/>

            <Achievements/>

            <Testomonials/>

        </Box>
    );
}