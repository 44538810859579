import React, { useState } from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ProductItem({ item }) {
    const [isHovered, setIsHovered] = useState(false);

    const formattedPrice = new Intl.NumberFormat('en-PK', {
        style: 'currency',
        currency: 'PKR',
        minimumFractionDigits: 2,
    }).format(item.sale_price);

    return (
        <Link
            to={`/${encodeURIComponent(item.name)}`}
            state={{ item }}
            style={{ textDecoration: 'none', color: 'inherit' }}
        >
            <Box
                overflow="hidden"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                    cursor: 'pointer',
                    borderRadius: '10px',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                        backgroundColor: 'white',
                        color: 'black',
                    },
                }}
            >
                <img
                    src={`https://posnew.conixsystem.com/${isHovered && item.images[1] ? item.images[1] : item.images[0]}`}
                    alt={item.name}
                    width="100%"
                    height="auto"
                    style={{ aspectRatio: '4/3', objectFit: 'cover', borderRadius: '10px 10px 0 0' }}
                />
                <Box p={2}>
                    <Typography
                        variant="h6"
                        sx={{
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                            hyphens: 'auto',
                            maxWidth: '100%',
                        }}
                    >
                        {item.name}
                    </Typography>

                    <Box
                        display="flex"
                        flexDirection="column"
                        mt={2}
                        gap={1}
                    >
                        <Typography variant="body1" fontWeight="bold">{formattedPrice}</Typography>
                        <Box display="flex" justifyContent="flex-start">
                            <Chip
                                label={item.current_stock > 0 ? "In Stock" : "Out of Stock"}
                                color={item.current_stock > 0 ? "success" : "error"}
                                size="small"
                                sx={{
                                    maxWidth: '100%',
                                    '& .MuiChip-label': {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Link>
    );
}