'use client'

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    InputBase,
    Button,
    Modal,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Box,
    Paper,
    Popper,
    Fade,
} from '@mui/material'
import {
    Search as SearchIcon,
    Menu as MenuIcon,
    Close as CloseIcon,
    ExpandMore as ExpandMoreIcon,
    ChevronRight as ChevronRightIcon,
    DarkMode as DarkModeIcon,
    LightMode as LightModeIcon,
    Palette as PaletteIcon
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { useTheme } from "../../Provider/ThemeProvider"
import { useGetCategory } from "../../hooks/useCategoryHook"
import { useGetSubCategory } from "../../hooks/useSubCategoryHook"
import { useMediaQuery } from "@mui/material"
import { useTheme as useThemeContext } from "@mui/material/styles"

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50ch',
        },
    },
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    left: 0,
    top: '100%',
    display: 'flex',
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: theme.palette.background.default,
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}))

const StyledButton = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}))

const Navbar = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const theme = useThemeContext()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [isOpen, setIsOpen] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [showCategories, setShowCategories] = useState(false)
    const [activeCategory, setActiveCategory] = useState(null)
    const [sidebarCategories, setSidebarCategories] = useState(false)
    const { currentTheme, setTheme } = useTheme()
    const { data: dataCategory, isLoading: isCategoryLoading, error: categoryError } = useGetCategory()
    const { data: dataSubCategory, isLoading: isSubCategoryLoading, error: subCategoryError } = useGetSubCategory()
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)

    const dropdownRef = useRef(null)
    const browseButtonRef = useRef(null)

    useEffect(() => {
        if (dataCategory) {
            setCategories(dataCategory)
        }
    }, [dataCategory])

    useEffect(() => {
        if (dataSubCategory) {
            const subCategoriesMap = {}
            dataSubCategory.forEach(subCat => {
                if (!subCategoriesMap[subCat.item_category]) {
                    subCategoriesMap[subCat.item_category] = []
                }
                subCategoriesMap[subCat.item_category].push(subCat)
            })
            setSubCategories(subCategoriesMap)
        }
    }, [dataSubCategory])

    const toggleSidebar = () => setIsOpen(!isOpen)
    const toggleSearch = () => setShowSearch(!showSearch)
    const toggleCategories = useCallback((event) => {
        setAnchorEl(event.currentTarget)
        setShowCategories(prev => !prev)
    }, [])
    const handleCategoryHover = (categoryId) => {
        setActiveCategory(categoryId)
    }
    const handleCategoryLeave = () => {
        setActiveCategory(null)
    }
    const toggleSidebarCategories = () => setSidebarCategories(!sidebarCategories)
    const toggleTheme = () => {
        if (currentTheme === 'light') setTheme('dark')
        else if (currentTheme === 'dark') setTheme('default')
        else setTheme('light')
    }
    const getThemeIcon = () => {
        if (currentTheme === 'light') return <DarkModeIcon />
        if (currentTheme === 'dark') return <LightModeIcon />
        return <PaletteIcon />
    }

    const handleSubCategoryClick = (categoryId, subCatId, event) => {
        event.preventDefault()
        const linkTo = `/laptops/${categoryId}/${subCatId}`
        navigate(linkTo)
        setShowCategories(false)
        setIsOpen(false)
    }

    const isActiveRoute = (path) => {
        return location.pathname === path
    }

    return (
        <AppBar position="static" color="default">
            <Toolbar>
                <Typography variant="h6" component={Link} to="/" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: { xs: 1, md: 0 } }}>
                    Conix
                </Typography>

                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ position: 'relative', display: { xs: 'none', md: 'block' } }}>
                        <SearchIcon sx={{ position: 'absolute', top: '50%', left: 8, transform: 'translateY(-50%)' }} />
                        <StyledInputBase
                            placeholder="Search..."
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Box>
                </Box>
                <IconButton
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    onClick={toggleSearch}
                    color="inherit"
                    aria-label="Toggle search"
                >
                    <SearchIcon />
                </IconButton>
                <IconButton
                    color="inherit"
                    onClick={toggleTheme}
                    aria-label={`Switch to ${currentTheme === 'light' ? 'dark' : currentTheme === 'dark' ? 'default' : 'light'} mode`}
                >
                    {getThemeIcon()}
                </IconButton>
                <IconButton
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    color="inherit"
                    onClick={toggleSidebar}
                    aria-label="Toggle menu"
                >
                    {isOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
            </Toolbar>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start', p: 1 }}>
                <Button
                    color="inherit"
                    onClick={toggleCategories}
                    endIcon={<ExpandMoreIcon />}
                    ref={browseButtonRef}
                    aria-haspopup="true"
                    aria-expanded={showCategories}
                >
                    Browse Categories
                </Button>
                <Button
                    color="inherit"
                    component={Link}
                    to="/"
                    sx={{
                        backgroundColor: isActiveRoute('/') ? 'action.selected' : 'inherit',
                        '&:hover': {
                            backgroundColor: isActiveRoute('/') ? 'action.selected' : 'action.hover',
                        }
                    }}
                >
                    Home
                </Button>
                <Button
                    color="inherit"
                    component={Link}
                    to="/laptops"
                    sx={{
                        backgroundColor: isActiveRoute('/laptops') ? 'action.selected' : 'inherit',
                        '&:hover': {
                            backgroundColor: isActiveRoute('/laptops') ? 'action.selected' : 'action.hover',
                        }
                    }}
                >
                    laptops
                </Button>
                <Button
                    color="inherit"
                    component={Link}
                    to="/contact"
                    sx={{
                        backgroundColor: isActiveRoute('/contact') ? 'action.selected' : 'inherit',
                        '&:hover': {
                            backgroundColor: isActiveRoute('/contact') ? 'action.selected' : 'action.hover',
                        }
                    }}
                >
                    Contact Us
                </Button>
            </Box>
            <Popper
                open={showCategories}
                anchorEl={anchorEl}
                placement="bottom-start"
                transition
                onMouseLeave={() => setShowCategories(false)}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <StyledPaper elevation={3} ref={dropdownRef}>
                            <List sx={{ minWidth: '200px', borderRight: 1, borderColor: 'divider' }}>
                                {categories.map((category) => (
                                    <StyledListItem
                                        button
                                        key={category.id}
                                        onMouseEnter={() => handleCategoryHover(category.id)}
                                        sx={{
                                            backgroundColor: activeCategory === category.id ? 'action.selected' : 'inherit',
                                        }}
                                    >
                                        <ListItemText primary={category.desc} />
                                        {subCategories[category.id] && <ChevronRightIcon />}
                                    </StyledListItem>
                                ))}
                            </List>
                            {activeCategory && (
                                <Box sx={{ p: 2, minWidth: '300px' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {categories.find(cat => cat.id === activeCategory)?.desc}
                                    </Typography>
                                    <List>
                                        {subCategories[activeCategory]?.map((subCat) => (
                                            <StyledListItem
                                                button
                                                component={Link}
                                                to={`/laptops/${activeCategory}/${subCat.id}`}
                                                key={subCat.id}
                                                onClick={(event) => handleSubCategoryClick(activeCategory, subCat.id, event)}
                                            >
                                                <ListItemText primary={subCat.desc} />
                                            </StyledListItem>
                                        ))}
                                    </List>
                                </Box>
                            )}
                        </StyledPaper>
                    </Fade>
                )}
            </Popper>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                {showSearch && (
                    <Box sx={{ p: 2 }}>
                        <StyledInputBase
                            placeholder="Search..."
                            fullWidth
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Box>
                )}
            </Box>
            <Modal
                open={isOpen}
                onClose={toggleSidebar}
                aria-labelledby="side-modal-title"
                aria-describedby="side-modal-description"
            >
                <Box
                    sx={{
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        width: '100%',
                        maxWidth: isMobile ? '100%' : 400,
                        height: '100%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6" component="h2" id="side-modal-title">
                            Menu
                        </Typography>
                        <IconButton onClick={toggleSidebar} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <List id="side-modal-description">
                        <StyledListItem button component={Link} to="/" onClick={toggleSidebar}>
                            <ListItemText primary="Home" />
                        </StyledListItem>
                        <ListItem button onClick={toggleSidebarCategories}>
                            <ListItemText primary="Browse Categories" />
                            {sidebarCategories ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </ListItem>
                        <Collapse in={sidebarCategories} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {categories.map((category) => (
                                    <React.Fragment key={category.id}>
                                        <StyledListItem button onClick={() => handleCategoryHover(category.id)} sx={{ pl: 4 }}>
                                            <ListItemText primary={category.desc} />
                                            {subCategories[category.id] && (
                                                activeCategory === category.id ? <ExpandMoreIcon /> : <ChevronRightIcon />
                                            )}
                                        </StyledListItem>
                                        <Collapse in={activeCategory === category.id} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {subCategories[category.id]?.map((subCat) => (
                                                    <StyledListItem
                                                        button
                                                        component={Link}
                                                        to={`/laptops/${category.id}/${subCat.id}`}
                                                        key={subCat.id}
                                                        sx={{ pl: 6 }}
                                                        onClick={(event) => handleSubCategoryClick(category.id, subCat.id, event)}
                                                    >
                                                        <ListItemText primary={subCat.desc} />
                                                    </StyledListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </React.Fragment>
                                ))}
                            </List>
                        </Collapse>
                        <StyledListItem button component={Link} to="/laptops" onClick={toggleSidebar}>
                            <ListItemText primary="laptops" />
                        </StyledListItem>
                        <StyledListItem button component={Link} to="/contact" onClick={toggleSidebar}>
                            <ListItemText primary="Contact Us" />
                        </StyledListItem>
                    </List>
                </Box>
            </Modal>
        </AppBar>
    )
}

export default Navbar