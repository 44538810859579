import React from 'react';
import {Avatar, Box, Container, Grid, Paper, Typography, useTheme} from "@mui/material";

function Index() {
    const theme = useTheme();
    const sectionStyle = {
        width: '100%',
        padding: theme.spacing(6, 0),
    };
    const darkSectionStyle = {
        ...sectionStyle,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    };

    const paperStyle = {
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
    };
    return (
        <Box component="section" sx={darkSectionStyle}>
            <Container>
                <Box sx={{textAlign: 'center', mb: 4}}>
                    <Typography variant="h3" component="h2" gutterBottom>Testimonials</Typography>
                    <Typography variant="body1" sx={{maxWidth: '600px', mx: 'auto'}}>
                        Hear what our clients have to say about their experience with us.
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    {[
                        {
                            name: "John Doe",
                            role: "CEO, Acme Inc.",
                            testimonial: "I've worked with this company on several projects and they always exceed my expectations. Their attention to detail and commitment to excellence is unparalleled."
                        },
                        {
                            name: "Jane Smith",
                            role: "Marketing Manager, XYZ Corp.",
                            testimonial: "The team's creativity and strategic approach have significantly improved our online presence. They're a pleasure to work with and deliver results."
                        },
                        {
                            name: "Alex Johnson",
                            role: "Founder, Tech Startup",
                            testimonial: "From concept to execution, their work is top-notch. They've been instrumental in helping us establish our brand identity."
                        }
                    ].map((testimony, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>

                            <Paper sx={{...paperStyle, height: '100%', display: 'flex', flexDirection: 'column', transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',  '&:hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: theme.shadows[4],
                                }}}>
                                <Avatar src="/placeholder-user.jpg" alt={testimony.name}
                                        sx={{width: 80, height: 80, mx: 'auto', mb: 2}}/>
                                <Typography variant="h6" gutterBottom>{testimony.name}</Typography>
                                <Typography variant="subtitle2" color="text.secondary"
                                            gutterBottom>{testimony.role}</Typography>
                                <Typography variant="body2"
                                            sx={{mt: 2, flexGrow: 1}}>"{testimony.testimonial}"</Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>

    );
}

export default Index;