import React from 'react';
import ShopPage from "./ShopPage/shop_page";

function Index({
                   category,
                   isCategoryLoading,
                   errorCategory,

                   subCategory,
                   isSubCategoryLoading,
                   errorSubCategory,

                   items,
                   isItemsLoading,
                   onCategorySelect,
                   itemsError,
                   onSubCategoryChange,
                   dataClassification,
                   isClassificationLoading,
                   classificationError,
                   dataManufacturers,
                   isManufacturersLoading,
                   manufacturersError,
               }) {
    return (
        <ShopPage category={category} isCategoryLoading={isCategoryLoading} errorCategory={errorCategory}
                  subCategory={subCategory} isSubCategoryLoading={isSubCategoryLoading}
                  errorSubCategory={errorSubCategory}
                  items={items} onCategorySelect={onCategorySelect} onSubCategoryChange={onSubCategoryChange}
                  isItemsLoading={isItemsLoading} itemsError={itemsError}
                  dataClassification={dataClassification}
                  isClassificationLoading={isClassificationLoading}
                  classificationError={classificationError}
                  dataManufacturers={dataManufacturers}
                  isManufacturersLoading={isManufacturersLoading}
                  manufacturersError={manufacturersError}
        />
    );
}

export default Index;