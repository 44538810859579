import React, {useEffect, useState} from 'react';
import Products from '../Home/Products/product';
import BestSellers from "./Best Seller/best-sellers";
import Carosuel from "./Carosuel/carosuel";
import Reviews from "./Reviews/review";
import Policies from "./Policies/Policies";
import {Box} from "@mui/material"

const Home_Page = () => {
    const accessKey = 'X94E74AMeqI8T1UgOZ3cH01edquv0S8FCXTkukJYVyU';

    const fetchImagesAndUpdateProduct = async (product) => {
        try {
            const response = await fetch(`https://api.unsplash.com/search/photos?query=${product.title}&client_id=${accessKey}&per_page=5`);
            const data = await response.json();
            const newImages = data.results.map(img => img.urls.small);

            setProducts(prevProducts => prevProducts.map(p =>
                p.id === product.id
                    ? {...p, image: newImages}
                    : p
            ));
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    useEffect(() => {
        products.forEach(product => {
            fetchImagesAndUpdateProduct(product);
        });
    }, []);
    const [products, setProducts] = useState([
        {
            id: 1,
            image: [], title: "Stylish Sunglasses",
            price: 29.99,
            rating: 4.5,
        },
        {
            id: 2,
            image: [], title: "Leather Crossbody Bag",
            price: 49.99,
            rating: 4.2,
        },
        {
            id: 3,
            image: [], title: "Wireless Headphones",
            price: 79.99,
            rating: 4.7,
        },
        {
            id: 4,
            image: [], title: "Classic Wristwatch",
            price: 59.99,
            rating: 4.4,
        },
        {
            id: 5,
            image: [], title: "Cozy Throw Blanket",
            price: 39.99,
            rating: 4.6,
        },
        {
            id: 6,
            image: [],
            title: "Ergonomic Office Chair",
            price: 199.99,
            rating: 4.8,
        },
    ]);


    return (
        <section>
            <Carosuel/>
            <Policies/>
            <Products products={products} title={"Gaming Laptops"} className="py-2"/>
            <Products products={products} title={"Desktops"} className="py-2"/>
            <Products products={products} title={"Accessories"} className="py-2"/>
            <BestSellers/>
            {/*<Reviews/>*/}
        </section>
    );
};

export default Home_Page;
