import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Chip,
    Rating,
    IconButton,
    useTheme,
    useMediaQuery,
    Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import HistoryIcon from '@mui/icons-material/History';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    minWidth: 200,
    marginBottom: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-10px)',
        boxShadow: theme.shadows[10],
    },
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
}));

const CarouselContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    marginBottom: theme.spacing(4),
    aspectRatio: '16 / 9',
    overflow: 'hidden',
}));

const CarouselButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
}));

const ImageContainer = styled(Box)({
    position: 'relative',
    paddingTop: '75%', // 4:3 aspect ratio
    overflow: 'hidden',
});

const StyledCardMedia = styled(CardMedia)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

export default function Component() {
    const accessKey = 'X94E74AMeqI8T1UgOZ3cH01edquv0S8FCXTkukJYVyU';

    const fetchImageAndUpdateProduct = async (bestSeller) => {
        try {
            const response = await fetch(`https://api.unsplash.com/search/photos?query=${bestSeller.name}&client_id=${accessKey}&per_page=5`);
            const data = await response.json();
            const newImage = data.results.map(img => img.urls.small);

            setBestSellers(prevProducts => prevProducts.map(p =>
                p.id === bestSeller.id
                    ? { ...p, image: newImage[0] }
                    : p
            ));
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    useEffect(() => {
        bestSellers.forEach(bestSeller => {
            fetchImageAndUpdateProduct(bestSeller);
        });
    }, []);

    const [bestSellers, setBestSellers] = useState([
        {
            id: 1,
            name: "Wireless Noise-Cancelling Headphones",
            price: 249.99,
            rating: 4.8,
            image: [],
            featured: true,
            trending: 1,
            weeklyRank: 2,
            allTimeRank: 1,
        },
        {
            id: 2,
            name: "Smart Fitness Tracker",
            price: 99.99,
            rating: 4.6,
            image: [],
            featured: false,
            trending: 2,
            weeklyRank: 1,
            allTimeRank: 3,
        },
        {
            id: 3,
            name: "4K Ultra HD Smart TV",
            price: 799.99,
            rating: 4.9,
            image: [],
            featured: true,
            trending: 3,
            weeklyRank: 3,
            allTimeRank: 2,
        },
        {
            id: 4,
            name: "Portable Bluetooth Speaker",
            price: 79.99,
            rating: 4.5,
            image: [],
            featured: false,
            trending: 4,
            weeklyRank: 4,
            allTimeRank: 5,
        },
        {
            id: 5,
            name: "Robot Vacuum Cleaner",
            price: 299.99,
            rating: 4.7,
            image: [],
            featured: false,
            trending: 5,
            weeklyRank: 5,
            allTimeRank: 4,
        },
        {
            id: 6,
            name: "Smartphone with 5G",
            price: 699.99,
            rating: 4.8,
            image: [],
            featured: true,
            trending: 6,
            weeklyRank: 6,
            allTimeRank: 6,
        },
    ]);

    const [sortOption, setSortOption] = useState('trending');
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const sortProducts = useCallback((products) => {
        switch (sortOption) {
            case 'trending':
                return [...products].sort((a, b) => a.trending - b.trending);
            case 'weekly':
                return [...products].sort((a, b) => a.weeklyRank - b.weeklyRank);
            case 'allTime':
                return [...products].sort((a, b) => a.allTimeRank - b.allTimeRank);
            default:
                return products;
        }
    }, [sortOption]);

    const sortedProducts = useMemo(() => sortProducts(bestSellers), [sortProducts, bestSellers]);
    const featuredProducts = useMemo(() => bestSellers.filter(product => product.featured), [bestSellers]);

    const handleCarouselNext = useCallback(() => {
        setCarouselIndex((prevIndex) => (prevIndex + 1) % featuredProducts.length);
    }, [featuredProducts.length]);

    const handleCarouselPrev = useCallback(() => {
        setCarouselIndex((prevIndex) => (prevIndex - 1 + featuredProducts.length) % featuredProducts.length);
    }, [featuredProducts.length]);

    const handleAddToCart = useCallback((productId) => {
        setIsLoading(true);
        setTimeout(() => {
            console.log(`Added product ${productId} to cart`);
            setIsLoading(false);
        }, 1000);
    }, []);
    const ImageContainer = styled('div')({
        position: 'relative',
        paddingTop: '75%', // 4:3 aspect ratio
        width: '100%',
        overflow: 'hidden',
    });

    const StyledCardMedia = styled('img')({
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    });
    return (
        <Container maxWidth="lg" sx={{ py: 8 }}>
            <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                Our Best Sellers
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Discover our most popular products loved by customers worldwide. Quality, innovation, and satisfaction guaranteed.
            </Typography>

            <CarouselContainer>
                {featuredProducts[carouselIndex]?.image ? (
                    <StyledCardMedia
                        component="img"
                        image={featuredProducts[carouselIndex].image}
                        alt={featuredProducts[carouselIndex].name}
                    />
                ) : (
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                )}
                <CarouselButton onClick={handleCarouselPrev} sx={{ left: 16 }} aria-label="Previous product">
                    <ArrowBackIosIcon />
                </CarouselButton>
                <CarouselButton onClick={handleCarouselNext} sx={{ right: 16 }} aria-label="Next product">
                    <ArrowForwardIosIcon />
                </CarouselButton>
                <Box sx={{ position: 'absolute', bottom: 16, left: 16, backgroundColor: 'rgba(0,0,0,0.7)', padding: 2, borderRadius: 1 }}>
                    <Typography variant="h6" sx={{ color: 'white' }}>{featuredProducts[carouselIndex]?.name}</Typography>
                    <Typography variant="subtitle1" sx={{ color: 'white' }}>${featuredProducts[carouselIndex]?.price.toFixed(2)}</Typography>
                </Box>
            </CarouselContainer>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
                <StyledFormControl>
                    <InputLabel id="sort-select-label">Sort by</InputLabel>
                    <Select
                        labelId="sort-select-label"
                        value={sortOption}
                        label="Sort by"
                        onChange={(e) => setSortOption(e.target.value)}
                    >
                        <MenuItem value="trending">
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TrendingUpIcon sx={{ mr: 1 }} />
                                Trending Now
                            </Box>
                        </MenuItem>
                        <MenuItem value="weekly">
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <HistoryIcon sx={{ mr: 1 }} />
                                This Week's Top Picks
                            </Box>
                        </MenuItem>
                        <MenuItem value="allTime">
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <EmojiEventsIcon sx={{ mr: 1 }} />
                                All-Time Favorites
                            </Box>
                        </MenuItem>
                    </Select>
                </StyledFormControl>
            </Box>

            <Grid container spacing={4}>
                {sortedProducts.map((product) => (
                    <Grid item key={product.id} xs={12} sm={6} md={4}>
                        <StyledCard>
                            <ImageContainer>
                                {product.image ? (
                                    <StyledCardMedia
                                        component="img"
                                        src={product.image}
                                        alt={product.name}
                                    />
                                ) : (
                                    <Skeleton variant="rectangular" width="100%" height="100%" />
                                )}
                            </ImageContainer>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography gutterBottom variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                                    {product.name}
                                </Typography>
                                <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    ${product.price.toFixed(2)}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <Rating name={`rating-${product.id}`} value={product.rating} precision={0.1} readOnly size="small" />
                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                                        ({product.rating.toFixed(1)})
                                    </Typography>
                                </Box>
                                {product.featured && (
                                    <Chip label="Featured" color="secondary" size="small" sx={{ mb: 1 }} />
                                )}
                                <Button
                                    variant="contained"
                                    startIcon={<ShoppingCartIcon />}
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    onClick={() => handleAddToCart(product.id)}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <Skeleton width={100} /> : 'Add to Cart'}
                                </Button>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ mt: 8, display: 'flex', justifyContent: 'center' }}>
                <Button variant="outlined" size="large" color="primary">
                    View All Best Sellers
                </Button>
            </Box>
        </Container>
    );
}