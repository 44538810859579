import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Radio, useTheme, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CarouselContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
}));

const SlideContainer = styled(Box)({
    display: 'flex',
    transition: 'transform 500ms ease-in-out',
});

const Slide = styled(Box)({
    position: 'relative',
    flexShrink: 0,
    width: '100%',
    height: '600px',
    ['@media (max-width:600px)']: {
        height: '300px',
    },
    ['@media (min-width:601px) and (max-width:960px)']: {
        height: '400px',
    },
    ['@media (min-width:961px) and (max-width:1280px)']: {
        height: '500px',
    },
});

const SlideImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const OverlayContent = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%)',
    color: 'white',
}));

const OverlayTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
}));

const OverlayDescription = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
}));

const ProgressBar = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 4,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
}));

const ProgressIndicator = styled(Box)(({ theme }) => ({
    height: '100%',
    backgroundColor: 'red',
    transition: 'width 500ms linear',
}));

const NavButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    color: 'white',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
}));

const RadioContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing(2),
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    gap: theme.spacing(1),
}));

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const theme = useTheme();

    const images = [
        {
            url: 'https://images.unsplash.com/photo-1498049794561-7780e7231661?w=1920&h=1080&fit=crop&q=80',
            title: 'Modern Workspace',
            description: 'Boost your productivity with our ergonomic office solutions.',
        },
        {
            url: 'https://images.unsplash.com/photo-1484704849700-f032a568e944?w=1920&h=1080&fit=crop&q=80',
            title: 'Premium Audio',
            description: 'Immerse yourself in crystal-clear sound with our headphones.',
        },
        {
            url: 'https://images.unsplash.com/photo-1519558260268-cde7e03a0152?w=1920&h=1080&fit=crop&q=80',
            title: 'Smart Home Devices',
            description: 'Transform your living space with cutting-edge smart technology.',
        },
        {
            url: 'https://images.unsplash.com/photo-1550009158-9ebf69173e03?w=1920&h=1080&fit=crop&q=80',
            title: 'Gaming Paradise',
            description: 'Level up your gaming experience with our high-performance gear.',
        },
        {
            url: 'https://images.unsplash.com/photo-1516387938699-a93567ec168e?w=1920&h=1080&fit=crop&q=80',
            title: 'Fitness Tech',
            description: 'Track your progress and achieve your fitness goals with smart wearables.',
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [images.length]);

    const handleRadioChange = (event) => {
        setCurrentIndex(parseInt(event.target.value, 10));
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    return (
        <CarouselContainer>
            <SlideContainer style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {images.map((image, index) => (
                    <Slide key={index}>
                        <SlideImage src={image.url} alt={`Slide ${index + 1}`} />
                        <OverlayContent>
                            <OverlayTitle variant="h3">{image.title}</OverlayTitle>
                            <OverlayDescription variant="h6">{image.description}</OverlayDescription>
                            <Button variant="contained" color="primary" size="large" sx={{ alignSelf: 'flex-start' }}>
                                Shop Now
                            </Button>
                        </OverlayContent>
                    </Slide>
                ))}
            </SlideContainer>

            <NavButton onClick={handlePrev} sx={{ left: theme.spacing(2) }}>
                <ArrowBackIosNewIcon />
            </NavButton>
            <NavButton onClick={handleNext} sx={{ right: theme.spacing(2) }}>
                <ArrowForwardIosIcon />
            </NavButton>

            <RadioContainer>
                {images.map((_, index) => (
                    <Radio
                        key={index}
                        checked={currentIndex === index}
                        onChange={handleRadioChange}
                        value={index}
                        name="carousel-radio"
                        size="small"
                        sx={{
                            color: 'rgba(255, 255, 255, 0.7)',
                            '&.Mui-checked': {
                                color: 'red',
                            },
                        }}
                    />
                ))}
            </RadioContainer>

            <ProgressBar>
                <ProgressIndicator
                    style={{ width: `${((currentIndex + 1) / images.length) * 100}%` }}
                />
            </ProgressBar>
        </CarouselContainer>
    );
};

export default Carousel;