import React from 'react'
import { Container, Typography, Grid, Paper, Box, useTheme } from '@mui/material'
import { Users, Award, Briefcase, TrendingUp } from 'lucide-react'

export default function Achievements() {
    const theme = useTheme()

    const darkSectionStyle = {
        width: '100%',
        padding: theme.spacing(6, 0),
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    }

    const paperStyle = {
        padding: theme.spacing(3),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: theme.shadows[4],
        },
    }

    const iconStyle = {
        fontSize: 48,
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
    }

    const statistics = [
        { icon: <Users />, value: '10,000+', label: 'Satisfied Clients' },
        { icon: <Award />, value: '50+', label: 'Industry Awards' },
        { icon: <Briefcase />, value: '500+', label: 'Projects Completed' },
        { icon: <TrendingUp />, value: '95%', label: 'Client Retention' },
    ]

    return (
        <Box component="section" sx={darkSectionStyle}>
            <Container>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Typography variant="h3" component="h2" gutterBottom>
                        Our Achievements
                    </Typography>
                    <Typography variant="body1" sx={{ maxWidth: '600px', mx: 'auto' }}>
                        We take pride in our work and the results we've achieved for our clients. Here are some key statistics that showcase our success.
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    {statistics.map((stat, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Paper sx={paperStyle} elevation={3}>
                                <Box sx={iconStyle}>{stat.icon}</Box>
                                <Typography variant="h4" component="p" gutterBottom>
                                    {stat.value}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    {stat.label}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}