import React, {useState, useMemo, useCallback, useEffect} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {
    Box,
    Grid,
    Pagination,
} from '@mui/material';
import FilterSidebar from '../FilterSidebar';
import ProductGrid from '../ProductGrid';

export default function ShopPage({
                                     category,
                                     isCategoryLoading,
                                     errorCategory,
                                     subCategory,
                                     isSubCategoryLoading,
                                     errorSubCategory,
                                     items,
                                     isItemsLoading,
                                     itemsError,
                                     onCategorySelect,
                                     onSubCategoryChange,
                                     dataClassification,
                                     isClassificationLoading,
                                     classificationError,
                                     dataManufacturers,
                                     isManufacturersLoading,
                                     manufacturersError,
                                 }) {
    const {categoryId, subCatId} = useParams();
    const [selectedCategory, setSelectedCategory] = useState(categoryId ? [categoryId] : []);
    const [selectedSubCategory, setSelectedSubCategory] = useState(subCatId ? [subCatId] : []);
    const [selectedFilters, setSelectedFilters] = useState({
        search: '',
        stockStatus: ['inStock'],
        priceRange: [0, 300000],
        sortBy: 'popularity',
        manufacturers: [],
        classifications: []
    });
    const [page, setPage] = useState(1);
    const [suggestions, setSuggestions] = useState([]);
    const itemsPerPage = 16;
    useEffect(() => {
        if (categoryId) {
            setSelectedCategory([categoryId]);
            onCategorySelect([categoryId]);
        } else {
            setSelectedCategory([]);
            onCategorySelect([]);
        }
        if (subCatId) {
            setSelectedSubCategory([subCatId]);
            onSubCategoryChange([subCatId]);
        } else {
            setSelectedSubCategory([]);
            onSubCategoryChange([]);
        }
    }, [categoryId, subCatId, onCategorySelect, onSubCategoryChange]);

    const handleCategorySelect = useCallback((selectedCategoryId) => {
        // console.log("shop_page" + selectedCategoryId)
        setSelectedCategory([selectedCategoryId]);
        onCategorySelect([selectedCategoryId]);
    }, [onCategorySelect]);

    const handleSubCategorySelect = useCallback((selectedSubCategoryId) => {
        setSelectedSubCategory([selectedSubCategoryId]);
        onSubCategoryChange([selectedSubCategoryId]);
    }, [onSubCategoryChange, selectedCategory]);


    const handleFilterChange = useCallback((filterType, value) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            [filterType]: value
        }));
        setPage(1);

        if (filterType === 'search' && value.length > 0) {
            const searchSuggestions = items
                .filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
                .slice(0, 5)
                .map(item => item.name);
            setSuggestions(searchSuggestions);
        } else {
            setSuggestions([]);
        }
    }, [items]);

    const handleSuggestionClick = (suggestion) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            search: suggestion
        }));
        setSuggestions([]);
    };

    const handleStockStatusChange = useCallback((status) => {
        setSelectedFilters(prevFilters => {
            // If 'All' is selected, set the stockStatus to 'all'
            // Otherwise, set it to the selected status
            return { ...prevFilters, stockStatus: status === 'all' ? ['all'] : [status] };
        });

        setPage(1);
    }, []);



    const filteredItems = useMemo(() => {
        return items.filter(item => {
            const { search, stockStatus, priceRange, manufacturers, classifications } = selectedFilters;
            const stockCondition = stockStatus.includes('all') ||
                (stockStatus.includes('inStock') && item.current_stock > 0) ||
                (stockStatus.includes('outOfStock') && item.current_stock <= 0);

            return (
                (search === "" || item.name.toLowerCase().includes(search.toLowerCase())) &&
                stockCondition &&
                parseFloat(item.sale_price) >= priceRange[0] &&
                parseFloat(item.sale_price) <= priceRange[1] &&
                (manufacturers.length === 0 || manufacturers.includes(item.manufacturer)) &&
                (classifications.length === 0 || classifications.includes(item.classification))
            );
        }).sort((a, b) => {
            switch (selectedFilters.sortBy) {
                case "priceAsc":
                    return parseFloat(a.sale_price) - parseFloat(b.sale_price);
                case "priceDesc":
                    return parseFloat(b.sale_price) - parseFloat(a.sale_price);
                default:
                    return 0;
            }
        });
    }, [items, selectedFilters]);

    const paginatedItems = useMemo(() => {
        const startIndex = (page - 1) * itemsPerPage;
        return filteredItems.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredItems, page, itemsPerPage]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <Box p={2}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                    <FilterSidebar
                        dataCategory={category}
                        isCategoryLoading={isCategoryLoading}
                        errorCategory={errorCategory}
                        dataSubCategory={subCategory}
                        isSubCategoryLoading={isSubCategoryLoading}
                        errorSubCategory={errorSubCategory}
                        selectedCategory={selectedCategory}
                        selectedSubCategory={selectedSubCategory}
                        selectedFilters={selectedFilters}
                        suggestions={suggestions}
                        handleCategorySelect={handleCategorySelect}
                        handleFilterChange={handleFilterChange}
                        handleSuggestionClick={handleSuggestionClick}
                        handleStockStatusChange={handleStockStatusChange}
                        handleSubCategorySelect={handleSubCategorySelect}
                        category_Id={categoryId}
                        items={items}
                    />
                </Grid>

                <Grid item xs={12} md={9}>
                    <ProductGrid
                        items={paginatedItems}
                        isLoading={isItemsLoading}
                        error={itemsError}
                        selectedFilters={selectedFilters}
                        handleFilterChange={handleFilterChange}
                        handleStockStatusChange={handleStockStatusChange}
                        selectedCategory={selectedCategory}
                        selectedSubCategory={selectedSubCategory}
                        categories={category}
                        subCategories={subCategory}
                        category_Id={categoryId}
                        dataClassification={dataClassification || []}
                        isClassificationLoading={isClassificationLoading}
                        classificationError={classificationError}
                        dataManufacturers={dataManufacturers || []}
                        isManufacturersLoading={isManufacturersLoading}
                        manufacturersError={manufacturersError}
                        setPage={setPage}
                    />
                    <Box display="flex" justifyContent="center" mt={4}>
                        <Pagination
                            count={Math.ceil(filteredItems.length / itemsPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}