import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1976d2',
            dark: '#1565c0',
        },
        secondary: {
            main: '#9c27b0',
            dark: '#7b1fa2',
        },
        background: {
            default: '#f5f5f5',
            paper: '#ffffff',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#bb86fc',
            dark: '#9965d4',
        },
        secondary: {
            main: '#03dac6',
            dark: '#02b3a2',
        },
        background: {
            default: '#2a2a2a',
            paper: 'rgba(255, 255, 255, 0.05)',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
});

export const defaultTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#2196f3',
            dark: '#1976d2',
        },
        secondary: {
            main: '#f50057',
            dark: '#c51162',
        },
        background: {
            default: '#e3f2fd',
            paper: '#ffffff',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
});

