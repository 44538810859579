import React, { useState, useRef, useEffect } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import {
    Typography,
    Button,
    Grid,
    Box,
    Chip,
    Tabs,
    Tab,
    Paper,
    CircularProgress,
    Modal,
    IconButton, Fade,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const StyledTabsContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.background.paper,
}));

const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: 'primary.main',
    },
});

const StyledTab = styled(Tab)({
    '&.Mui-selected': {
        color: 'primary.main',
    },
});

const ScrollableImageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    '-ms-overflow-style': 'none',
    [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
    },
}));

const ImageMagnifier = ({
                            src,
                            className,
                            width,
                            height,
                            alt,
                            magnifierHeight,
                            magnifierWidth,
                            zoomLevel
                        }) => {
    const [showMagnifier, setShowMagnifier] = useState(false);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [[x, y], setXY] = useState([0, 0]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const mouseEnter = (e) => {
        if (!isMobile) {
            const el = e.currentTarget;
            const { width, height } = el.getBoundingClientRect();
            setSize([width, height]);
            setShowMagnifier(true);
        }
    };

    const mouseLeave = () => {
        setShowMagnifier(false);
    };

    const mouseMove = (e) => {
        if (!isMobile) {
            const el = e.currentTarget;
            const { top, left } = el.getBoundingClientRect();

            const x = e.pageX - left - window.scrollX;
            const y = e.pageY - top - window.scrollY;

            setXY([x, y]);
        }
    };

    return (
        <div
            className="flex justify-center items-center"
            style={{
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}

        >
            <div className="relative inline-block">
                <img
                    src={src}
                    className={className}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                    }}
                    alt={alt}
                    onMouseEnter={mouseEnter}
                    onMouseLeave={mouseLeave}
                    onMouseMove={mouseMove}
                />
                {!isMobile && (
                    <div
                        style={{
                            display: showMagnifier ? '' : 'none',
                            position: 'absolute',
                            pointerEvents: 'none',
                            height: `${magnifierHeight}px`,
                            width: `${magnifierWidth}px`,
                            opacity: '1',
                            border: '1px solid lightgrey',
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            backgroundImage: `url('${src}')`,
                            backgroundRepeat: 'no-repeat',
                            top: `${y - magnifierHeight / 2}px`,
                            left: `${x - magnifierWidth / 2}px`,
                            backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
                            backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
                            backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
                        }}
                    />
                )}
            </div>
        </div>
    );
};


export default function ProductDetailPage() {
    const { name } = useParams();
    const [activeImage, setActiveImage] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    const specificationsRef = useRef(null);
    const accessoriesRef = useRef(null);

    const location = useLocation();
    const navigate = useNavigate();
    const productUrl = `${window.location.origin}${location.pathname}`;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const fetchProduct = async () => {
            setLoading(true);
            try {
                if (location.state?.item) {
                    setProduct(location.state.item);
                } else {
                    const response = await fetch(`https://posnew.conixsystem.com/api/itemName?name=${encodeURIComponent(name)}`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch product');
                    }
                    const data = await response.json();
                    if (data.items && data.items.length > 0) {
                        setProduct(data.items[0]);
                    } else {
                        throw new Error('Product not found');
                    }
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [name, location.state]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        const refs = [specificationsRef, accessoriesRef];
        refs[newValue].current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + 100;
            const sections = [
                {ref: specificationsRef, index: 0},
                {ref: accessoriesRef, index: 1},
            ];

            for (const section of sections) {
                if (section.ref.current && scrollPosition >= section.ref.current.offsetTop) {
                    setActiveTab(section.index);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleImageClick = () => {
        if (isMobile) {
            setModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handlePrevImage = () => {
        setActiveImage((prev) => (prev > 0 ? prev - 1 : product.images.length - 1));
    };

    const handleNextImage = () => {
        setActiveImage((prev) => (prev < product.images.length - 1 ? prev + 1 : 0));
    };

    const handleTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > 50;
        const isRightSwipe = distance < -50;
        if (isLeftSwipe) {
            handleNextImage();
        } else if (isRightSwipe) {
            handlePrevImage();
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{maxWidth: 1200, margin: '0 auto', padding: 2}}>
                <Typography variant="h5" color="error" gutterBottom>
                    Error: {error}
                </Typography>
                <Button component={Link} to="/shop" variant="contained">
                    Back to Shop
                </Button>
            </Box>
        );
    }

    if (!product) {
        return (
            <Box sx={{maxWidth: 1200, margin: '0 auto', padding: 2}}>
                <Typography variant="h5" gutterBottom>
                    Product not found
                </Typography>
                <Button component={Link} to="/shop" variant="contained">
                    Back to Shop
                </Button>
            </Box>
        );
    }

    const whatsappLink = `https://wa.me/+923254290003?text=I'm%20interested%20in%20${encodeURIComponent(product.name)}%20-%20${encodeURIComponent(productUrl)}`;

    return (
        <Box sx={{maxWidth: 1200, margin: '0 auto', padding: 2}}>
            <Button component={Link} to="/shop" variant="outlined" sx={{mb: 2}}>
                Back to Shop
            </Button>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Box onClick={handleImageClick}>
                        <ImageMagnifier
                            src={`https://posnew.conixsystem.com/${product.images[activeImage]}`}
                            alt={product.name}
                            width="100%"
                            height={isMobile ? "300px" : "400px"}
                            className="object-contain"
                            magnifierHeight={150}
                            magnifierWidth={150}
                            zoomLevel={1.5}
                        />
                    </Box>
                    <ScrollableImageContainer sx={{ mt: 2 }}>
                        {product.images.map((image, index) => (
                            <Button
                                key={index}
                                onClick={() => setActiveImage(index)}
                                sx={{
                                    width: 60,
                                    height: 60,
                                    flexShrink: 0,
                                    p: 0,
                                    m: 0.5,
                                    border: index === activeImage ? '2px solid' : 'none',
                                    borderColor: 'primary.main',
                                }}
                            >
                                <img
                                    src={`https://posnew.conixsystem.com/${image}`}
                                    alt={`${product.name} thumbnail ${index + 1}`}
                                    style={{width: '100%', height: '100%', objectFit: 'scale-down'}}
                                />
                            </Button>
                        ))}
                    </ScrollableImageContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>{product.name}</Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        {product.remarks && product.remarks.length > 0 ? (
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                {product.remarks.map((remark, index) => (
                                    <li key={index}>{remark}</li>
                                ))}
                                {product.classification && <li>{product.classification}</li>}
                            </ul>
                        ) : (
                            'No description available.'
                        )}
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" gutterBottom>
                            Rs.{Number(product.sale_price).toFixed(0)}
                        </Typography>
                        {product.current_stock > 0 ? (
                            <Chip label="In Stock" color="success"/>
                        ) : (
                            <Chip label="Out of Stock" color="error"/>
                        )}
                    </Box>
                    <Box mt={2}>
                        <Button
                            variant="contained"
                            startIcon={<WhatsAppIcon/>}
                            fullWidth
                            href={whatsappLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Contact on WhatsApp
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <StyledTabsContainer>
                <Paper elevation={3}>
                    <StyledTabs
                        value={activeTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="Product information tabs"
                    >
                        <StyledTab label="Specifications" id="product-tab-0" aria-controls="product-tabpanel-0"/>
                        <StyledTab label="Accessories" id="product-tab-1" aria-controls="product-tabpanel-1"/>
                    </StyledTabs>
                </Paper>
            </StyledTabsContainer>

            <Box sx={{mt: 4}}>
                <Box ref={specificationsRef} sx={{mb: 8}}>
                    <Typography variant="h5" gutterBottom>Specifications</Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        {product.remarks && product.remarks.length > 0 ? (
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                {product.remarks.map((remark, index) => (
                                    <li key={index}>{remark}</li>
                                ))}
                                {product.classification && <li>{product.classification}</li>}
                            </ul>
                        ) : (
                            'Product does not have any specifications.'
                        )}
                    </Typography>
                </Box>

                <Box ref={accessoriesRef} sx={{mb: 8}}>
                    <Typography variant="h5" gutterBottom>Accessories</Typography>
                    <Typography variant="body1">With original charger and power cable</Typography>
                </Box>
            </Box>

            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition // Close modal after the transition ends
            >
                <Fade in={modalOpen}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transition: 'all 0.3s ease-in-out', // Transition for the Box itself
                    }}>
                        <Box sx={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            transition: 'all 0.3s ease-in-out', // Transition for image container
                        }}>
                            <img
                                src={`https://posnew.conixsystem.com/${product.images[activeImage]}`}
                                alt={product.name}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '70vh',
                                    objectFit: 'contain',
                                    transition: 'opacity 0.3s ease-in-out', // Image transition effect
                                }}
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                            />
                            <IconButton
                                onClick={handlePrevImage}
                                sx={{
                                    position: 'absolute',
                                    left: 0,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    transition: 'all 0.3s ease-in-out' // Icon transition effect
                                }}
                            >
                                <ArrowBackIosNewIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleNextImage}
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    transition: 'all 0.3s ease-in-out' // Icon transition effect
                                }}
                            >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Box>
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            {`Image ${activeImage + 1} of ${product.images.length}`}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
}