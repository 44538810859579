import React from 'react'
import {
    Box,
    Typography,
    Card,
    CardContent,
    Grid,
    Avatar,
    Chip,
    Rating,
    ThemeProvider,
    createTheme,
    CssBaseline,
} from '@mui/material'
import { styled } from '@mui/system'
import OwlCarousel from 'react-owl-carousel'

// Note: In a real project, you would need to import the CSS files for Owl Carousel
// import 'owl.carousel/dist/assets/owl.carousel.css'
// import 'owl.carousel/dist/assets/owl.theme.default.css'

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
})

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
    backgroundColor: theme.palette.background.paper,
}))

const brands = [
    {
        id: 1,
        name: "Apple",
        logo: "https://fxdnbzgdabxsbvhfkxhz.supabase.co/storage/v1/object/public/logos/apple-logo.png",
        products: ["MacBook Pro", "MacBook Air", "iMac"],
    },
    {
        id: 2,
        name: "Dell",
        logo: "https://fxdnbzgdabxsbvhfkxhz.supabase.co/storage/v1/object/public/logos/dell-logo.png",
        products: ["XPS", "Inspiron", "Alienware"],
    },
    {
        id: 3,
        name: "HP",
        logo: "https://fxdnbzgdabxsbvhfkxhz.supabase.co/storage/v1/object/public/logos/hp-logo.png",
        products: ["Spectre", "Envy", "Pavilion"],
    },
    {
        id: 4,
        name: "Lenovo",
        logo: "https://fxdnbzgdabxsbvhfkxhz.supabase.co/storage/v1/object/public/logos/lenovo-logo.png",
        products: ["ThinkPad", "Yoga", "Legion"],
    },
]

const reviews = [
    {
        id: 1,
        name: "Alex Johnson",
        avatar: "https://fxdnbzgdabxsbvhfkxhz.supabase.co/storage/v1/object/public/avatars/avatar1.jpg",
        date: "2023-05-15",
        rating: 5,
        comment: "The MacBook Pro exceeded my expectations. It's incredibly fast and the battery life is amazing!",
    },
    {
        id: 2,
        name: "Sam Lee",
        avatar: "https://fxdnbzgdabxsbvhfkxhz.supabase.co/storage/v1/object/public/avatars/avatar2.jpg",
        date: "2023-06-02",
        rating: 4,
        comment: "I'm really happy with my Dell XPS. Great performance, though the fan can get a bit noisy under heavy load.",
    },
    {
        id: 3,
        name: "Taylor Swift",
        avatar: "https://fxdnbzgdabxsbvhfkxhz.supabase.co/storage/v1/object/public/avatars/avatar3.jpg",
        date: "2023-06-20",
        rating: 5,
        comment: "The HP Spectre is not just beautiful, it's a powerhouse. Perfect for both work and entertainment.",
    },
    {
        id: 4,
        name: "Emma Watson",
        avatar: "https://fxdnbzgdabxsbvhfkxhz.supabase.co/storage/v1/object/public/avatars/avatar4.jpg",
        date: "2023-07-05",
        rating: 4,
        comment: "The Lenovo ThinkPad is a reliable workhorse. Great keyboard and build quality!",
    },
]

const ReviewCarousel = ({ reviews }) => {
    const options = {
        loop: true,
        margin: 20,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    }

    return (
        <OwlCarousel className="owl-theme" {...options}>
            {reviews.map((review) => (
                <Box key={review.id} sx={{ padding: 2 }}>
                    <StyledCard>
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <Avatar
                                    src={review.avatar}
                                    alt={review.name}
                                    sx={{ marginRight: 2, width: 60, height: 60 }}
                                />
                                <Box>
                                    <Typography variant="subtitle1" component="h3">
                                        {review.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {new Date(review.date).toLocaleDateString()}
                                    </Typography>
                                </Box>
                            </Box>
                            <Rating value={review.rating} readOnly sx={{ marginBottom: 1 }} />
                            <Typography variant="body2" color="text.secondary">
                                {review.comment}
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Box>
            ))}
        </OwlCarousel>
    )
}

export default function Component() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ maxWidth: 'lg', margin: 'auto', padding: 4, bgcolor: 'background.default' }}>
                <Box component="section" sx={{ marginBottom: 8 }}>
                    <Typography variant="h3" component="h2" align="center" gutterBottom color="text.primary">
                        Shop by Brand
                    </Typography>
                    <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
                        Explore our curated selection of top computer brands and their flagship products
                    </Typography>
                    <Grid container spacing={4}>
                        {brands.map((brand) => (
                            <Grid item key={brand.id} xs={12} sm={6} md={3}>
                                <StyledCard>
                                    <CardContent>
                                        <Box display="flex" alignItems="center" mb={2}>
                                            <Avatar
                                                src={brand.logo}
                                                alt={brand.name}
                                                sx={{ width: 60, height: 60, marginRight: 2 }}
                                            />
                                            <Typography variant="h6" component="h3" color="text.primary">
                                                {brand.name}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            {brand.products.map((product, index) => (
                                                <Chip
                                                    key={index}
                                                    label={product}
                                                    size="small"
                                                    sx={{ margin: 0.5, bgcolor: 'primary.main', color: 'primary.contrastText' }}
                                                />
                                            ))}
                                        </Box>
                                    </CardContent>
                                </StyledCard>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                <Box component="section">
                    <Typography variant="h3" component="h2" align="center" gutterBottom color="text.primary">
                        Customer Reviews
                    </Typography>
                    <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
                        Read what our customers have to say about their recent purchases
                    </Typography>
                    <ReviewCarousel reviews={reviews} />
                </Box>
            </Box>
        </ThemeProvider>
    )
}