import React from 'react';
import { Box, Skeleton } from '@mui/material';

export default function ProductSkeleton() {
    return (
        <Box
            sx={{
                borderRadius: '10px',
                overflow: 'hidden',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'white',
                },
            }}
        >
            <Skeleton
                variant="rectangular"
                width="100%"
                height={0}
                sx={{
                    paddingTop: '75%',
                    borderRadius: '10px 10px 0 0',
                }}
            />
            <Box p={2}>
                <Skeleton variant="text" width="80%" height={32} /> {/* Product name */}
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Skeleton variant="text" width="40%" height={24} /> {/* Price */}
                    <Skeleton variant="rectangular" width="30%" height={32} sx={{ borderRadius: '16px' }} /> {/* Stock status chip */}
                </Box>
            </Box>
        </Box>
    );
}