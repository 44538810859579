import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import {
    Button,
    Card,
    CardContent,
    Typography,
    CssBaseline,
    Box,
    IconButton,
    Rating,
    useTheme
} from "@mui/material";
import { styled } from '@mui/system';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';



const CarouselContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(4, 6),
    '&:hover .nav-button': {
        opacity: 1,
    },
}));

const NavButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white,
    opacity: 0,
    transition: 'opacity 0.3s ease',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.left': {
        left: theme.spacing(1),
    },
    '&.right': {
        right: theme.spacing(1),
    },
}));

const ProductCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: theme.shadows[10],
    },
}));

const ProductImage = styled('img')({
    width: '100%',
    height: 200,
    objectFit: 'cover',
});

const ProductCarousel = ({ title, products }) => {
    const theme = useTheme();
    const carouselRef = React.useRef(null);








    const options = {
        loop: true,
        nav: false,
        dots: false,
        margin: 20,
        responsive: {
            0: { items: 1 },
            600: { items: 2 },
            960: { items: 3 },
            1200: { items: 4 },
        },
    };

    const handlePrev = () => {
        carouselRef.current.prev();
    };

    const handleNext = () => {
        carouselRef.current.next();
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <CarouselContainer>
                <Typography variant="h4" component="h2" gutterBottom sx={{ mb: 4 }}>
                    {title}
                </Typography>
                <OwlCarousel className='owl-theme' {...options} ref={carouselRef}>
                    {products.map((product) => (
                        <Box key={product.id} sx={{ px: 1 }}>
                            <ProductCard>
                                <ProductImage
                                    src={product.image}
                                    alt={product.title}
                                    loading="lazy"
                                />
                                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h6" component="h3" gutterBottom noWrap>
                                        {product.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                        {product.description ? product.description.slice(0, 60) + '...' : 'No description available.'}
                                    </Typography>
                                    <Box sx={{ mt: 'auto' }}>
                                        <Typography variant="h6" component="p" sx={{ mb: 1 }}>
                                            {product.price ? `$${product.price.toFixed(2)}` : 'Price not available'}
                                        </Typography>
                                        <Rating value={product.rating || 0} readOnly size="small" sx={{ mb: 2 }} />
                                        <Button variant="outlined" fullWidth>
                                            View Details
                                        </Button>
                                    </Box>
                                </CardContent>
                            </ProductCard>
                        </Box>
                    ))}
                </OwlCarousel>
                <NavButton className="nav-button left" onClick={handlePrev} aria-label="Previous product">
                    <ChevronLeftIcon />
                </NavButton>
                <NavButton className="nav-button right" onClick={handleNext} aria-label="Next product">
                    <ChevronRightIcon />
                </NavButton>
            </CarouselContainer>
        </React.Fragment>
    );
};

export default ProductCarousel;