import React from 'react';
import { Link } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Box,
    Button,
    Container,
    Grid,
    Typography,
    InputBase,
    Divider
} from "@mui/material";
import { Landscape as MountainIcon } from '@mui/icons-material';

function Footer() {

    return (
        <AppBar position="static" color="default" elevation={0} component="footer">
            <Container maxWidth="lg">
                <Toolbar disableGutters sx={{ py: { xs: 6, md: 8, lg: 10 } }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Link to="#" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <MountainIcon sx={{ fontSize: 24 }} />
                                    <Typography variant="h6" component="span">Conix</Typography>
                                </Link>
                                <Typography variant="body2" color="text.secondary">
                                    Discover the best products for your home and lifestyle.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="h6" gutterBottom>Quick Links</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Link to="#" style={{ textDecoration: 'none', color: 'text.secondary', '&:hover': { color: 'primary.main' } }}>About</Link>
                                <Link to="#" style={{ textDecoration: 'none', color: 'text.secondary', '&:hover': { color: 'primary.main' } }}>Shop</Link>
                                <Link to="#" style={{ textDecoration: 'none', color: 'text.secondary', '&:hover': { color: 'primary.main' } }}>Contact</Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="h6" gutterBottom>Categories</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Link to="#" style={{ textDecoration: 'none', color: 'text.secondary', '&:hover': { color: 'primary.main' } }}>Home</Link>
                                <Link to="#" style={{ textDecoration: 'none', color: 'text.secondary', '&:hover': { color: 'primary.main' } }}>Fashion</Link>
                                <Link to="#" style={{ textDecoration: 'none', color: 'text.secondary', '&:hover': { color: 'primary.main' } }}>Electronics</Link>
                                <Link to="#" style={{ textDecoration: 'none', color: 'text.secondary', '&:hover': { color: 'primary.main' } }}>Outdoor</Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="h6" gutterBottom>Newsletter</Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Subscribe to our newsletter to get the latest updates and offers.
                            </Typography>
                            <Box component="form" sx={{ display: 'flex', gap: 1 }}>
                                <InputBase
                                    placeholder="Enter your email"
                                    sx={{
                                        flex: 1,
                                        p: 1,
                                        border: 1,
                                        borderColor: 'divider',
                                        borderRadius: 1,
                                        bgcolor: 'background.paper',
                                    }}
                                />
                                <Button type="submit" variant="contained" color="primary">
                                    Subscribe
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
                <Divider />
                <Toolbar disableGutters sx={{ py: 2, flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                        &copy; 2024 Acme Store. All rights reserved.
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Link to="#" style={{ textDecoration: 'none', color: 'text.secondary', '&:hover': { color: 'primary.main' } }}>
                            Terms of Service
                        </Link>
                        <Link to="#" style={{ textDecoration: 'none', color: 'text.secondary', '&:hover': { color: 'primary.main' } }}>
                            Privacy Policy
                        </Link>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Footer;