import React, { createContext, useState, useContext, useCallback } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// Assume these themes are imported from another file
import { lightTheme, darkTheme, defaultTheme } from '../Theme/theme';
const ThemeContext = createContext();

export function ThemeProvider({ children }) {
    const [currentTheme, setCurrentTheme] = useState('default');

    const themeMap = {
        light: lightTheme,
        dark: darkTheme,
        default: defaultTheme,
    };

    const setTheme = useCallback((theme) => {
        if (themeMap[theme]) {
            setCurrentTheme(theme);
        } else {
            console.warn(`Theme "${theme}" not found. Using default theme.`);
            setCurrentTheme('default');
        }
    }, []);

    return (
        <ThemeContext.Provider value={{ currentTheme, setTheme }}>
            <MuiThemeProvider theme={themeMap[currentTheme]}>
                <CssBaseline />
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
}

export function useTheme() {
    const context = useContext(ThemeContext);
    if (context === undefined) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
}

export default ThemeProvider;