// src/App.js
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Navbar from './components/common/navbar';
import Routes from './routes/routes';
import Footer from "./components/common/footer";

function App() {
    return (
        <Router>
            <Navbar/>
            <main>
                <Routes/>
            </main>
            <Footer/>
        </Router>
    );
}

export default App;
