import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CategorySkeleton from '../CategorySkeleton';

const DEFAULT_CATEGORY_ID = 6;

export default function Component({
                                      dataCategory,
                                      isCategoryLoading,
                                      errorCategory,
                                      dataSubCategory,
                                      isSubCategoryLoading,
                                      errorSubCategory,
                                      selectedFilters,
                                      suggestions,
                                      handleCategorySelect,
                                      handleFilterChange,
                                      handleSuggestionClick,
                                      handleSubCategorySelect,
                                      category_Id,
                                      items
                                  }) {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState({});
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);

    const urlCategoryId = searchParams.get('category') ? Number(searchParams.get('category')) : DEFAULT_CATEGORY_ID;
    const urlSubCategoryId = searchParams.get('subcategory') ? Number(searchParams.get('subcategory')) : null;

    const [categoryCounts, setCategoryCounts] = useState({});
    const [subCategoryCounts, setSubCategoryCounts] = useState({});

    const initialSubCategoryCounts = useRef({});

    useEffect(() => {
        const countItems = () => {
            const catCounts = {};
            const subCatCounts = {};

            items.forEach(item => {
                if (item.current_stock > 0) {
                    catCounts[item.category] = (catCounts[item.category] || 0) + 1;
                    subCatCounts[item.sub_category] = (subCatCounts[item.sub_category] || 0) + 1;
                }
            });

            setCategoryCounts(catCounts);
            setSubCategoryCounts(subCatCounts);

            if (Object.keys(initialSubCategoryCounts.current).length === 0) {
                initialSubCategoryCounts.current = subCatCounts;
            }
        };

        countItems();
    }, [items]);

    useEffect(() => {
        if (urlCategoryId) {
            setExpandedCategory(urlCategoryId);
        }
        if (urlSubCategoryId) {
            setSelectedSubCategory(urlSubCategoryId);
        }
    }, [urlCategoryId, urlSubCategoryId]);

    useEffect(() => {
        if (dataCategory && dataCategory.length > 0) {
            if (urlCategoryId) {
                const selectedCategory = dataCategory.find(cat => cat.id === urlCategoryId);
                if (selectedCategory) {
                    setCategories([selectedCategory]);
                    setExpandedCategory(urlCategoryId);
                } else {
                    setCategories(dataCategory);
                }
            } else {
                setCategories(dataCategory);
            }
        }
    }, [dataCategory, urlCategoryId]);

    useEffect(() => {
        if (dataSubCategory && dataSubCategory.length > 0) {
            const subCategoriesMap = {};
            dataSubCategory.forEach(subCat => {
                if (!subCategoriesMap[subCat.item_category]) {
                    subCategoriesMap[subCat.item_category] = [];
                }
                subCategoriesMap[subCat.item_category].push(subCat);
            });
            setSubCategories(subCategoriesMap);
        }
    }, [dataSubCategory]);

    const handleCategoryClick = useCallback((clickedCategoryId) => {
        setExpandedCategory(prevExpanded => prevExpanded === clickedCategoryId ? null : clickedCategoryId);
        handleCategorySelect(clickedCategoryId);
        setSelectedSubCategory(null);
        handleSubCategorySelect(null);
        searchParams.set('category', clickedCategoryId.toString());
        searchParams.delete('subcategory');
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }, [handleCategorySelect, navigate, location.pathname, searchParams]);

    const handleSubCategoryClick = useCallback((categoryId, subCatId, event) => {
        event.stopPropagation();
        const newSelectedSubCategory = selectedSubCategory === subCatId ? null : subCatId;

        setSelectedSubCategory(newSelectedSubCategory);
        handleSubCategorySelect(newSelectedSubCategory);

        searchParams.set('category', categoryId.toString());
        if (newSelectedSubCategory === null) {
            searchParams.delete('subcategory');
        } else {
            searchParams.set('subcategory', subCatId.toString());
        }
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }, [handleSubCategorySelect, navigate, location.pathname, searchParams, selectedSubCategory]);

    return (
        <Box p={2} borderRadius={1}>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6" gutterBottom fontWeight="bold">
                        Filters
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold">Search</Typography>
                            <TextField
                                fullWidth
                                type="search"
                                placeholder="Search products..."
                                value={selectedFilters.search}
                                onChange={(e) => handleFilterChange("search", e.target.value)}
                            />
                            {suggestions.length > 0 && (
                                <List>
                                    {suggestions.map((suggestion, index) => (
                                        <ListItem
                                            key={index}
                                            button
                                            onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                            <ListItemText primary={suggestion} />
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                {urlCategoryId ? 'Subcategories' : 'Categories'}
                            </Typography>
                            {isCategoryLoading ? (
                                <CategorySkeleton />
                            ) : errorCategory ? (
                                <Typography color="error">Error loading categories</Typography>
                            ) : categories.length === 0 ? (
                                <Typography>No categories available</Typography>
                            ) : (
                                <List>
                                    {categories.map((category) => (
                                        <Accordion
                                            key={category.id}
                                            expanded={expandedCategory === category.id}
                                            onChange={() => handleCategoryClick(category.id)}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`category-${category.id}-content`}
                                                id={`category-${category.id}-header`}
                                            >
                                                <Typography>{category.desc} </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {isSubCategoryLoading ? (
                                                    <CategorySkeleton />
                                                ) : errorSubCategory ? (
                                                    <Typography color="error">Error loading subcategories</Typography>
                                                ) : !subCategories[category.id] || subCategories[category.id].length === 0 ? (
                                                    <Typography>No subcategories available</Typography>
                                                ) : (
                                                    <List>
                                                        {subCategories[category.id].map((subCat) => (
                                                            <ListItem
                                                                key={subCat.id}
                                                                button
                                                                onClick={(event) => handleSubCategoryClick(category.id, subCat.id, event)}
                                                                selected={selectedSubCategory === subCat.id}
                                                            >
                                                                <ListItemText primary={`${subCat.desc} (${subCategoryCounts[subCat.id] || initialSubCategoryCounts.current[subCat.id] || 0})`} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </List>
                            )}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
